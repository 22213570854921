.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* text-align: center; */
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

}

.title {
    font-size: 30px;
    font-family: 'e-Ukraine-Regular Head';
    text-align: center;
    animation: show;
    animation-duration: 1s;
    animation-timing-function: ease;
}

.description {
    text-align: center;
    max-width: 240px;
    font-size: 16px;
    margin: 20px 0 10px 0;
    color: rgb(139, 111, 139);
    animation: show2;
    animation-duration: .7s;
    animation-timing-function: ease;
}

.holder {
    display: flex;
    font-size: 35px;
    /* color: rgb(246, 0, 47); */
    color: rgb(66, 51, 57);
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(130, 122, 122, 0.421);
    padding-top: 20px;
}

.icon {
    transition: .2s ease;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: rgb(228, 234, 240);
    /* animation: show;
    animation-duration: 1s;
    animation-timing-function: ease; */
}

.icon:hover {
    transition: .5s ease;
    /* transform: scale(1.2); */
    background-color: rgb(218, 223, 232);
    transform: translateY(-5px);
    cursor: pointer;
}

.bold {
    font-weight: 600;
    color: rgb(66, 59, 59);
}

@keyframes show {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes show2 {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 450px) {
    .holder {
        max-width: 250px;
    }
}